<template>
  <!-- BEGIN: Content-->
  <div class="app-content" style="margin-top: -25px;">
    <div class="content-wrapper container-xxl p-0">

      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
        </div>
        <div class="content-header-right col-md-3 col-12 my-1">
          <div class="float-end">
            <button
                type="button"
                @click="redirectBack"
                class="btn btn-close"
            >
            </button>
          </div>
        </div>
      </div>

      <div class="content-body">
        <div id="user-profile">
          <!-- profile header -->
          <div class="row">
            <div class="col-12">
              <div class="card profile-header profile_header_visibility mb-2">
                <ContactBanner
                    :name="updateFormData.full_name??''"
                    :designation="updateFormData.designation??''"
                    :company_roles_data="updateFormData.company_roles_data??''"
                    :profilePhoto="updateFormData.photo??''"
                    :coverPhoto="updateFormData.cover_photo??''"
                    :showProfilePhoto="true"
                    :isEditable="contactId"
                    @photoUploaded="uploadPhoto"
                />
                <!-- tabs pill -->
                <ContactNav
                    :activeTabId="activeTabId"
                    :tabs="tabs"
                    @setActiveTab="setActiveTab"
                    :infoType="infoType"
                />

              </div>
            </div>
          </div>
          <!--/ profile header -->

          <!-- profile info section -->
          <section id="profile-info">
            <div class="row">
              <div class="col-md-12 col-12">

                <div class="card">
                  <component 
                    :formData="updateFormData" 
                    :items="items" 
                    :companyRules="companyRules" 
                    :doctors="doctors"
                    @storeContact="storeContact"
                    :is="activeTab"
                    :isLoading="loading"
                  />
                </div>

              </div>
            </div>
          </section>
          <!--/ profile info section -->
        </div>

      </div>
    </div>
  </div>
  <Loader v-if="loading"/>
  <!-- END: Content-->
</template>

<script setup>
import {computed, inject, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import Loader from '@/components/atom/LoaderComponent'
import ContactNav from '@/components/molecule/contact/ContactNav.vue'
import BasicInfo from "@/components/molecule/company/hospital/patient/create-update/BasicInfo.vue";
import Guardian from "@/components/molecule/company/hospital/patient/create-update/Guardian.vue";
import Email from "@/views/page/company/contact/tabs/person/Email.vue";
import Address from "@/views/page/company/contact/tabs/person/Address.vue";
import handleCompanyContact from "@/services/modules/companyContact";
import handleRole from "@/services/modules/role";
import ContactBanner from "@/components/molecule/company/hospital/patient/create-update/ContactBanner.vue";

const {
  storeCompanyContact,
  storeCompanyContactsItems,
  storeAdditionalContactItems,
  storeUpdateContactFile,
  fetchSingleCompanyContact,
  fetchSingleCompanyContactItems,
  fetchCompanyAllContacts
} = handleCompanyContact();

const {fetchCompanyDefaultRoles} = handleRole();

const route = useRoute();
const router = useRouter();
const activeTab = ref(BasicInfo)
const activeTabId = ref('basic-info')
const infoType = ref('person');
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const companyRules = ref([])
const doctors = ref([])
const updateFormData = ref({})
const items = ref([])

const contactId = computed(() => route.query.contactId)

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

const redirectBack = () => {
  if (contactId.value) {
    let query = {
      page: 1,
      contactId: contactId.value
    }
    router.push({name: 'patient-register', query});
    return;
  }
  router.back();
}

const tabs = [
  {id: 'basic-info', module: 'person', title: 'Basic Info', component: BasicInfo},
  {id: 'address', module: 'person', title: 'Address', component: Address},
  {id: 'email', module: 'both', title: 'Email', component: Email},
  {id: 'guardian_name', module: 'person', title: 'Guardian', component: Guardian}
]


const uploadPhoto = async (image, type) => {
  loading.value = true
  let form = new FormData();
  form.append('file', image)
  form.append('type', type)

  storeUpdateContactFile(contactId.value, form, getQuery()).then(res => {
    loading.value = false
    if (!res.status) {
      return showError(res.message)
    }
    updateFormData.value[type] = res.data;
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}
const storeContact = async (formData) => {
  let payload = {
    contact_type: infoType.value,
    contact_id: contactId.value,
    active_tab: activeTabId.value,
    ...formData
  }

  const validTabs = ['basic-info', 'address'];
  const checkActiveTab = validTabs.includes(activeTabId.value);

  loading.value = true;

  const routeRes = checkActiveTab ? storeCompanyContact : storeCompanyContactsItems;

  await routeRes(payload, getQuery()).then(res => {
    
    if (!res.status) {
      return showError(res.message)
    }

    if (!checkActiveTab) {
      items.value = res.data
      return showSuccess(res.message)
    }

    let routeQuery = Object.assign({}, route.query)
    routeQuery.contactId = res.data.id
    router.push({path: route.path, query: routeQuery})
    updateFormData.value = res.data

    if (activeTabId.value === 'basic-info') {
      const {mobile_no, blood_group, referred_by, marital_status, religion, occupation} = formData
      let keys = {mobile_no, blood_group, referred_by, marital_status, religion, occupation}
      saveAdditionalContactItems(res.data.id, keys, getQuery())
    }
    showSuccess(res.message)
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const saveAdditionalContactItems = (contactId, keys, getQuery) => {
  storeAdditionalContactItems(contactId, keys, getQuery).then(res => {
    loading.value = false
    if (!res.status) {
      return showError(res.message)
    }
    updateFormData.value = {...updateFormData.value, ...res.data};
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}
const getSingleContact = (id) => {
  loading.value = true
  let query = getQuery();
  query += "&active_tab=" + activeTabId.value;
  query += "&call_from=patient";
  const keys = ['mobile_no', 'blood_group', 'referred_by', 'marital_status', 'religion', 'occupation'];
  const keysQueryParam = keys.join(',');

  query += "&keys=" + keysQueryParam;
  fetchSingleCompanyContact(query, id).then(res => {
    loading.value = false
    if (!res.status) {
      return showError(res.message)
    }
    updateFormData.value = res.data
    items.value = res.data.items
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const getSingleContactItems = () => {
  loading.value = true
  let query = getQuery();
  query += "&active_tab=" + activeTabId.value;
  fetchSingleCompanyContactItems(query, contactId.value).then(res => {
    loading.value = false
    if (!res.status) {
      return showError(res.message)
    }
    items.value = res.data
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  loading.value = true;
  const companyQuery = `?company_id=${route.params.companyId}&roles=patient`
  const rolesRes = fetchCompanyDefaultRoles(companyQuery)
  let query = getQuery();
  query += "&roles=doctor";
  query += '&dropdown=' + true;
  const doctorsRes = fetchCompanyAllContacts(query)
  Promise.all([
    rolesRes.then(({data}) => {
      if (data) companyRules.value = data
    }),
    doctorsRes.then(({data}) => {
      if (data) doctors.value = data
    })
  ])
      .then(() => {
        loading.value = false
      })
      .catch((err) => {
        loading.value = false
      })

  const hashPath = route.hash.substring(1);
  if (hashPath) {
    activeTabId.value = hashPath;
    const tab = tabs.find(item => (item.id === hashPath || item.id === 'more'));
    activeTab.value = tab.component;
  }

  if (contactId.value) {
    getSingleContact(contactId.value)
  }

  if (route.query.type ?? '') {
    infoType.value = route.query.type;
  }
})

const setActiveTab = tab => {
  activeTab.value = tab.component;
  activeTabId.value = tab.id;

  const validTabs = ['basic-info', 'org-basic-info', 'address'];
  const checkActiveTab = validTabs.includes(activeTabId.value);
  if (!checkActiveTab) {
    getSingleContactItems()
  }
};


</script>


<style>

.org_icon_col {
  padding-bottom: 5%;
  position: relative;
}

.org_dlt_icon {
  position: absolute;
  right: 0px;
  top: -17px;
}

.button_icon {
  border: none !important;
  padding: 4px 15px !important;
}

.p_left_0 {
  padding-left: 0 !important;
}

.header_txt {
  /* margin-left: -15% !important; */
  justify-content: space-evenly !important;
}

.box_shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.card_body {
  padding: 2% 2%;
  width: 48%;
  margin: 1%;
  border-radius: 5px
}

@media (max-width: 414px) {
  .card_body {
    width: auto !important;
  }

  ._skill_btn_visibility {
    display: grid !important;
  }

  ._skill_out_visibility {
    display: none !important;
  }

  .org_icon_col {
    padding-bottom: 10% !important;
  }

  .org_dlt_icon {
    top: -8px !important;
  }
}
</style>
<style scoped>

.profile_sub_menu.dropdown-menu {
  min-width: 11rem !important;
}

.profile_sub_menu .nav-pills .nav-link, .nav-tabs .nav-link {
  justify-content: center !important;
}


</style>

