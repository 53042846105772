<template>
  <div class="card-header border-bottom pt-1 pb-1 header_txt">
    <h4 class="card-title">Guardian</h4>
  </div>
  <div class="card-body mt-2">
    <form class="form form-horizontal" @submit.prevent="$emit('storeContact', {'items':guardians})">

      <div class="row">
        <div class="col-sm-6 offset-md-3 col-12">
          <div v-for="(formData, index) in guardians" :key="index" class="row">
            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="full-address" class="form-control" v-model="formData.guardian_name"
                       placeholder="Guardian's Name"/>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="full-name" class="form-control" v-model="formData.guardian_mobile_no"
                       placeholder="Guardian's Mobile no"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="full-name" class="form-control" v-model="formData.guardian_occupation"
                       placeholder="Guardian's Occupation"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="full-name" class="form-control" v-model="formData.guardian_address"
                       placeholder="Guardian's Address"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="full-name" class="form-control" v-model="formData.guardian_relationship"
                       placeholder="Relationship"/>
              </div>
            </div>

            <div class="col-sm-6 offset-sm-3">
              <button type="submit" class="btn btn-primary me-1">Submit</button>
              <button type="reset" class="btn btn-outline-secondary">Reset</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>

import {defineProps, ref, watch} from "vue";

const props = defineProps({
  items: {
    type: Array
  }
})

const guardians = ref([
  {
    guardian_name: '',
    guardian_mobile_no: '',
    guardian_occupation: '',
    guardian_address: '',
    guardian_relationship: ''
  }
])

watch(() => props.items, (newFormData) => {
  formDataSet();
});

const formDataSet = () => {
  if (props.items.length) {
    guardians.value = props.items
  }
}
</script>

<style scoped></style>
